module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Drupal Web Academy', // Navigation and Site Title
  titleAlt: 'Drupal Web Academy', // Title for JSONLD
  description: 'Drupal Web Academy - Passion pour Drupal',
  url: 'https://drupalwebacademy.com/', // Domain of your site. No trailing slash!
  siteUrl: 'https://drupalwebacademy.com/', // url + pathPrefix
  siteLanguage: 'en', // Language Tag on <html> element
  logo: 'static/logo/logo.png', // Used for SEO
  banner: 'static/logo/banner.png',
  // JSONLD / Manifest
  favicon: 'static/logo/favicon.png', // Used for manifest favicon generation
  shortName: 'D-W-A', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Akram ZAIRIG', // Author for schemaORGJSONLD
  themeColor: '#3e7bf2',
  backgroundColor: '#d3e0ff',
  twitter: '@akram_zairig', // Twitter Username
};
